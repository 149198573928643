import {Button, FormControl, FormLabel} from "react-bootstrap";
import {useState} from "react";
import AlertMessage from "../../components/alertMessage";
import "./login.css"
import config from "../../global";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {

    const navigate = useNavigate();

    const [form, setForm] = useState({
        username: "",
        password: ""
    })

    const [notification, setNotification] = useState({
        "message": "",
        "type": ""
    })

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        fetch(config.API_URL+"/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form)
        }).then(
            res => {
                if (res.status === 200) {
                    res.json().then(
                        data => {
                            localStorage.setItem("authorization", data.token)
                            localStorage.setItem("full_name", data.name)
                            localStorage.setItem("role", data.role)

                            navigate("/dashboard")
                        }
                    )

                }

                setNotification({
                    "message": "Invalid username or password",
                    "type": "danger"
                })
            }
        )
    }

    return (
        <div className={"center-div"}>
            <div className={"text-center color-primary"}>
                <h1 className={"fw-bold display-4"}>CARVAN AL FAZILAH</h1>
                <h4 className={"fw-bold display-6"}>(PVT) LTD.</h4>
            </div>

            <div className={"text-center"}>
                <h1>Sign In</h1>
                <p className={"fs-5"}>WELCOME TO INTERNAL AFFAIR PORTAL</p>
            </div>
            {
                notification.message ?
                    <AlertMessage message={notification.message} type={notification.type}/> : null
            }
            <form onSubmit={handleSubmit}>
                <div className={"mb-3"}>
                    <FormLabel className={"fw-bold"}>USERNAME</FormLabel>
                    <FormControl className={"py-3"} type={"text"} onChange={handleChange} name={"username"}
                                 placeholder={"Enter Username"} required={true}></FormControl>
                </div>
                <div className={"mb-3"}>
                    <FormLabel className={"fw-bold"}>PASSWORD</FormLabel>
                    <FormControl className={"py-3"} type={"password"} onChange={handleChange} name={"password"}
                                 placeholder={"Enter Password"} required={true}></FormControl>
                </div>
                <div>
                    <Button type={"submit"} className={"w-100 py-3"}>Sign In</Button>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;