import "./dashboard.css"
import {Link} from "react-router-dom";

const DashboardPage = () => {

    const is_admin = localStorage.getItem("role") === "admin";

    return (
        <>
            <div>
                <p className={"fs-3"}>WELCOME TO INTERNAL AFFAIR PORTAL</p>
            </div>
            <div>
                <h5>ACTIONS</h5>
                <div className={"d-flex gap-4"}>
                    <Link className={"circle-link"} to={"/client"}>
                        <div className={"nav-circle"}>
                            CL
                        </div>
                        <span>CLIENTS</span>
                    </Link>
                    <Link className={"circle-link"} to={"/vendor"}>
                        <div className={"nav-circle"}>
                            VN
                        </div>
                        <span>VENDORS</span>
                    </Link>
                    <Link className={"circle-link"} to={"/staff"}>
                        <div className={"nav-circle"}>
                            ST
                        </div>
                        <span>STAFF</span>
                    </Link>
                    {
                        is_admin && <>
                            <Link className={"circle-link"} to={"/invoice"}>
                                <div className={"nav-circle"}>
                                    IN
                                </div>
                                <span>INVOICE</span>
                            </Link>
                        </>
                    }
                    <Link className={"circle-link"} to={"/voucher/cash-receive"}>
                        <div className={"nav-circle"}>
                            CR
                        </div>
                        <span>CASH RECEIVE</span>
                    </Link>
                    <Link className={"circle-link"} to={"/voucher/cash-pay"}>
                        <div className={"nav-circle"}>
                            CP
                        </div>
                        <span>CASH PAYMENT</span>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default DashboardPage;