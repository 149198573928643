import {useEffect, useState} from "react";
import {Button, Col, FormControl, FormLabel, FormSelect, Row} from "react-bootstrap";
import config from "../../global";
import AlertMessage from "../../components/alertMessage";

const CashPaymentPage = () => {
    const [cashPaymentDetail, setCashPaymentDetail] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [formData, setFormData] = useState({
        vendor_code: "",
        description: "",
        amount: "",
        cheque_no: "",
        posting_date: "",
    });
    const [totalAmount, setTotalAmount] = useState(0);

    const [error, setError] = useState({
        message: "",
        type: ""
    });

    // Update Total Amount whenever cashPaymentDetail changes
    useEffect(() => {
        // Calculate total amount
        const total = cashPaymentDetail.reduce((acc, item) => acc + parseFloat(item.amount), 0);
        setTotalAmount(total);
    }, [cashPaymentDetail]);

    // Fetch client data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL+"/vendor/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                });
                const data = await response.json();
                setVendorData(data);
            } catch (error) {
                setError(
                    {
                        message: error.message,
                        type: "danger"
                    }
                )
            }
        };

        fetchData();
    }, []);

    //Fetch all cash payments
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/cash/all/cash-payment", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                });
                const data = await response.json();
                setCashPaymentDetail(data);
            } catch (error) {
                setError(
                    {
                        message: error.message,
                        type: "danger"
                    }
                )
            }
        };

        fetchData();
    }, []);

    // Handle form input change
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    // Add or update cash receive
    const handleAddCashPayment = (e) => {
        e.preventDefault();

        fetch(config.API_URL + "/cash/add/cash-payment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            },
            body: JSON.stringify(formData)
        }).then(res => {
            if (res.status === 500) {
                setError(
                    {
                        message: "Something went wrong!",
                        type: "danger"
                    }
                )
            } else {
                // Clear the form
                setFormData({
                    vendor_code: "",
                    description: "",
                    amount: "",
                    cheque_no: "",
                    posting_date: "",
                });

                setCashPaymentDetail([...cashPaymentDetail, formData]);
                setError(
                    {
                        message: "Cash Payment Added Successfully!",
                        type: "success"
                    }
                )
            }
        })
    };

    return (
        <>
            <div className={"mb-3"}>
                <h4 className={"fw-bold"}>ADD CASH PAYMENT</h4>
                {
                    error.message ? <AlertMessage message={error.message} type={error.type}/> : ""
                }
                <form onSubmit={handleAddCashPayment}>
                    <Row>
                        <Col md={2}>
                            <FormLabel>VENDOR CODE</FormLabel>
                            <FormSelect
                                name={"vendor_code"}
                                value={formData.vendor_code}
                                onChange={handleInputChange}
                                required
                            >
                                <option value={""}>Select Vendor</option>
                                {vendorData.map((vendor) => (
                                    <option key={vendor.vendor_code} value={vendor.vendor_code}>
                                        {vendor.vendor_code} - {vendor.vendor_name}
                                    </option>
                                ))}
                            </FormSelect>
                        </Col>
                        <Col>
                            <FormLabel>DESCRIPTION</FormLabel>
                            <FormControl
                                name={"description"}
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder={"Description"}
                                required
                            ></FormControl>
                        </Col>
                        <Col md={2}>
                            <FormLabel>AMOUNT</FormLabel>
                            <FormControl
                                name={"amount"}
                                type={"number"}
                                placeholder={"Amount"}
                                min={0}
                                value={formData.amount}
                                onChange={handleInputChange}
                                required
                            ></FormControl>
                        </Col>
                        <Col>
                            <FormLabel>CHEQUE NO</FormLabel>
                            <FormControl
                                name={"cheque_no"}
                                value={formData.cheque_no}
                                placeholder={"Cheque No"}
                                onChange={handleInputChange}
                            ></FormControl>
                        </Col>
                        <Col sm={12} md={1} className={"d-flex align-items-end"}>
                            <Button className={"w-100 fw-bold"} type={"submit"}>
                                ADD
                            </Button>
                        </Col>
                    </Row>
                </form>
            </div>
            <h4 className={"fw-bold"}>CASH PAYMENT VOUCHER</h4>
            <table>
                <thead>
                <tr>
                    <th>VENDOR CODE</th>
                    <th>DESCRIPTION</th>
                    <th>AMOUNT</th>
                    <th>CHEQUE NO</th>
                    <th>POSTING DATE</th>
                </tr>
                </thead>
                <tbody>
                {cashPaymentDetail.map((cashPayment, index) => (
                    <tr key={index}>
                        <td>{cashPayment.vendor_code}</td>
                        <td>{cashPayment.description}</td>
                        <td>{cashPayment.amount}</td>
                        <td>{cashPayment.cheque_no}</td>
                        <td>{cashPayment.created_at}</td>
                    </tr>
                ))}
                {Array.from({length: Math.max(0, 10 - cashPaymentDetail.length)}).map((_, index) => (
                    <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className={"d-flex justify-content-end align-items-end gap-3"}>

                <div>
                    <span className={"fw-bold"}>TOTAL AMOUNT: </span>
                    <div className={"bg-white border-color-primary text-center py-2"}>
                        <h5 className={"m-0"}>{totalAmount}</h5>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CashPaymentPage;
