import {Button, FormControl, InputGroup} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Search} from "react-bootstrap-icons";
import carvanLogo from "../logo.png";

const Header = () => {

    const full_name = (localStorage.getItem("full_name") || "ANONYMOUS").toUpperCase();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    }

    return (
        <>
            <div className={"mb-3"}>
                <div className={"d-flex justify-content-between mb-3"}>
                    <div className={"d-flex gap-4 align-items-center"}>
                        <img width={"50px"} src={carvanLogo} alt={"Carvanalfazilah logo"}/>
                        <InputGroup>
                            <FormControl
                                placeholder="Search Here.."
                            />
                            <InputGroup.Text><Search/></InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className={"d-flex gap-4 align-items-center"}>
                        <img width={"50px"} src={carvanLogo} alt={"Carvanalfazilah logo"}/>
                        <div>
                            <h5 className={"fw-bold color-primary m-0"}>WELCOME!</h5>
                            <span className={"fw-bold"}>
                                {full_name}
                            </span>
                        </div>
                        <Button onClick={handleLogout} variant={"secondary"} className={"fw-bold"}>LOGOUT</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;