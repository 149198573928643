import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import config from "../../global";

const ViewInvoicePage = () => {
    const {client_code} = useParams();
    const [invoiceData, setInvoiceData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + `/invoice/get`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                    body: JSON.stringify({
                        "client_code": client_code,
                    }),
                });

                if (response.status === 404) {
                    setInvoiceData(null); // Explicitly set to null for not found
                } else {
                    const data = await response.json();
                    setInvoiceData(data);
                }
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
            }
        };

        fetchData();
    }, [client_code]);

    const handlePrint = () => {
        const printableContent = document.getElementById("printable").innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printableContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload(); // Reload to restore the original content
    };

    if (loading) return <div>Loading...</div>; // Show a loading state while data is being fetched

    return (
        <>
            <div id="printable">
                <div>
                    <h3>Invoice #{invoiceData?.invoice_code || "-"}</h3>
                    <div className="pt-3">
                        <h5 className="fw-bold">Client Information</h5>
                        <table>
                            <thead>
                            <tr>
                                <th>Account Code</th>
                                <th>Account Name</th>
                                <th>Passport Number</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{invoiceData?.client?.code || "-"}</td>
                                <td>{invoiceData?.client?.name || "-"}</td>
                                <td>{invoiceData?.client?.passport_number || "-"}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pt-3">
                        <h5 className="fw-bold">Client Payment Detail</h5>
                        <table>
                            <thead>
                            <tr>
                                <th>R No#</th>
                                <th>Receivable</th>
                                <th>Desc</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{invoiceData?.client?.payment_detail?.receipt_number || "-"}</td>
                                <td>{invoiceData?.client?.payment_detail?.total_recivable || "-"}</td>
                                <td>{invoiceData?.client?.payment_detail?.description || "-"}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="pt-3">
                        <h5 className="fw-bold">Payable Breakup</h5>
                        <table>
                            <thead>
                            <tr>
                                <th>Account Code</th>
                                <th>Account Name</th>
                                <th>Currency</th>
                                <th>FC Amount</th>
                                <th>Ex. Rate</th>
                                <th>Payable</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoiceData?.vendor?.map((payable, index) => (
                                <tr key={index}>
                                    <td>{payable?.code || "-"}</td>
                                    <td>{payable?.name || "-"}</td>
                                    <td>{payable?.currency.toUpperCase() || "-"}</td>
                                    <td>{payable?.fc_amount || "-"}</td>
                                    <td>{payable?.exchange_rate || "-"}</td>
                                    <td>{payable?.payable || "-"}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="pt-3">
                        <h5 className="fw-bold">Totals</h5>
                        <table>
                            <thead>
                            <tr>
                                <th>Total Payable</th>
                                <th>Total Receivable</th>
                                <th>Profit</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{invoiceData?.total_payable || "-"}</td>
                                <td>{invoiceData?.total_receivable || "-"}</td>
                                <td>{invoiceData?.total_profit || "-"}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={"my-3"}>
                <Button onClick={handlePrint}>Print Invoice</Button>
            </div>
        </>
    );
};

export default ViewInvoicePage;
