import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotFoundPage from "../error/404NotFound";
import {Button, Col, Row} from "react-bootstrap";
import config from "../../global";

const ClientLedgerPage = () => {

    const {client_code} = useParams()
    const [notFound, setNotFound] = useState(false)
    const [clientDetails, setClientDetails] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/client/get_full_detail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                    body: JSON.stringify({
                        "client_code": client_code
                    }),
                });

                if (response.status === 404) {
                    setNotFound(true)
                }

                const data = await response.json();
                setClientDetails(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching client data:", error);
            }
        };

        fetchData();
    }, [client_code])

    if (notFound) {
        return <NotFoundPage/>
    }

    const handlePrint = () => {
        const printableContent = document.getElementById("printable").innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = `
            <h2>${clientDetails.client.name} Ledgers</h2>
            ${printableContent}
        `;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload(); // Reload to restore the original content
    };

    return (
        <>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : clientDetails && (
                <>
                    <div className={"d-flex justify-content-between mb-3"}>
                        <div>
                            <h3 className={"fw-bold"}>Client Ledgers</h3>
                            <h6>{clientDetails.client.name} - {clientDetails.client.code}</h6>
                        </div>
                        <div className={"d-flex align-items-center"}>
                            <Button onClick={handlePrint} className={"fw-bold"}>PRINT</Button>
                        </div>
                    </div>
                    <div id={"printable"}>
                        <table>
                            <thead>
                            <tr>
                                <th className={"equal"}>DATE</th>
                                <th className={"equal"}>TKT/R#</th>
                                <th className={"equal"}>TYPE</th>
                                <th>DESCRIPTION</th>
                                <th className={"equal"}>DEBIT</th>
                                <th className={"equal"}>CREDIT</th>
                                <th className={"equal"}>BALANCE</th>
                                <th className={"equal"}>V/T</th>
                                <th className={"equal"}>C/O</th>
                            </tr>
                            </thead>
                            <tbody>
                            {clientDetails.ledgers.map((ledger) => (
                                <tr key={ledger.receipt_number}>
                                    <td>{ledger.created_at}</td>
                                    <td>{ledger.receipt_number}</td>
                                    <td>{ledger.ledger_type}</td>
                                    <td>{ledger.description}</td>
                                    <td>{ledger.debit || "-"}</td>
                                    <td>{ledger.credit || "-"}</td>
                                    <td>{ledger.balance}</td>
                                    <td>{ledger.type.toUpperCase()}</td>
                                    <td>{clientDetails?.invoices[0]?.staff.name}</td>
                                </tr>
                            ))}
                            {
                                Array.from({length: Math.max(0, 15 - clientDetails.ledgers.length)}).map((_, index) => (
                                    <tr key={index}>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <Row className={"justify-content-end mt-3"}>
                            <Col sm={12} md={6} lg={4} className={"d-flex gap-1"}>
                                <div className={"p-3"}>
                                    <h5 className={"fw-bold m-0"}>TOTAL</h5>
                                </div>
                                <div className={"bg-white px-3 border-color-primary"}>
                                    <small className={"color-primary fw-bold"}>CR</small>
                                    <h5 className={"m-0"}>{clientDetails.total_credit}</h5>
                                </div>
                                <div className={"bg-white px-3 border-color-primary"}>
                                    <small className={"color-primary fw-bold"}>DB</small>
                                    <h5 className={"m-0"}>{clientDetails.total_debit}</h5>
                                </div>
                                <div className={"bg-white px-3 border-color-primary"}>
                                    <small className={"color-primary fw-bold"}>BL</small>
                                    <h5 className={"m-0"}>{clientDetails.balance}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            )
            }
        </>
    );
}

export default ClientLedgerPage;