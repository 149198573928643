import {Button, FormControl, FormLabel, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import AlertMessage from "../../components/alertMessage";
import config from "../../global";

const StaffPage = () => {
    const [staffData, setStaffData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [form, setForm] = useState({
        "staff_name": "",
        "staff_password": "",
        "staff_username": ""
    });
    const [editForm, setEditForm] = useState({
        "staff_code": "",
        "staff_name": "",
        "staff_password": "",
        "staff_username": ""
    })
    const [error, setError] = useState({
        "message": "",
        "type": ""
    });

    const is_admin = localStorage.getItem("role") === "admin";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/staff/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                });
                const data = await response.json();
                setStaffData(data);
            } catch (error) {
                setError(
                    {
                        "message": "Error fetching Staff data. Please refresh the page and try again.",
                        "type": "danger"
                    }
                )
            }
        }

        fetchData();
    }, []);

    const handleModalShow = () => {
        setModalShow(true);
    }

    const handleEditModalShow = (staff_code) => {
        const staff = staffData.find((staff) => staff.staff_code === staff_code);
        setEditForm(staff);

        setEditModalShow(true);
    }
    const handleModalClose = () => {
        setModalShow(false);
    }
    const handleEditModalClose = () => {
        setEditModalShow(false);
    }
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const handleEditChange = (e) => {
        setEditForm({
            ...editForm,
            [e.target.name]: e.target.value
        })
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();

        fetch(config.API_URL + "/staff/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            },
            body: JSON.stringify(form),
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Adding Staff!",
                        "type": "danger"
                    })
                } else if (res.status === 403) {
                    setModalShow(false);
                    setError({
                        "message": "Username Already Exists!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setModalShow(false);
                    setForm({
                        "staff_name": "",
                        "staff_password": "",
                        "staff_username": ""
                    })
                    res.json().then((data) => {
                        setStaffData([
                            ...staffData,
                            {
                                "staff_code": data.staff.staff_code,
                                "staff_name": data.staff.staff_name,
                                "staff_username": data.staff.staff_username,
                                "created_at": data.staff.created_at,
                                "is_banned": false
                            }
                        ])
                    })

                    setError({
                        "message": "Staff Added Successfully!",
                        "type": "success"
                    })
                }

            })
    }

    const deleteStaff = (staff_code) => {
        fetch(config.API_URL + `/staff/delete/${staff_code}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            }
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Deleting Staff!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setStaffData(staffData.filter((staff) => staff.staff_code !== staff_code));
                    setError({
                        "message": "Staff Deleted Successfully!",
                        "type": "danger"
                    })
                }
            })
    }

    const handleEditFormSubmit = (e) => {
        e.preventDefault();
        fetch(config.API_URL + "/staff/edit", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            },
            body: JSON.stringify(editForm),
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    "message": "Error Editing Staff!",
                    "type": "danger"
                })
            } else if (res.status === 403) {
                setEditModalShow(false);
                setError({
                    "message": "Username Already Exists!",
                    "type": "danger"
                })
            } else if (res.status === 200) {
                setEditModalShow(false);
                res.json().then((data) => {
                    setStaffData(staffData.map((staff) => staff.staff_code === data.staff.staff_code ? data.staff : staff))
                })
                setError({
                    "message": "Staff Edited Successfully!",
                    "type": "warning"
                })
            }
        })
    }
    return (
        <>
            <div className={"d-flex justify-content-between mb-2"}>
                <h3 className={"fw-bold"}>STAFF</h3>
                {
                    is_admin &&  <Button onClick={handleModalShow} className={"fw-bold"}>Add New Staff</Button>
                }
            </div>
            {
                error.message && <AlertMessage message={error.message} type={error.type}/>
            }
            <div className={"my-3"}>
                <table>
                    <thead>
                    <tr>
                        <th className={"equal"}>CODE</th>
                        <th className={"equal"}>STAFF NAME</th>
                        <th className={"equal"}>ACTION</th>
                        <th className={"equal"}>DEBIT</th>
                        <th className={"equal"}>CREDIT</th>
                        <th className={"equal"}>BALANCE</th>
                        <th className={"equal"}>V/T</th>
                    </tr>
                    </thead>
                    <tbody>
                    {staffData.map((staff, index) => (
                        <tr key={index}>
                            <td className={staff.is_banned ? "bg-danger" : ""}>{staff.staff_code}</td>
                            <td>
                                {staff.staff_name} - [{staff.staff_username}]
                            </td>
                            <td>
                                <div className={"d-flex gap-2"}>
                                    {
                                        is_admin && <>
                                            <span onClick={() => handleEditModalShow(staff.staff_code)}
                                                  className={"text-decoration-underline"}>Edit</span>
                                            <span onClick={() => deleteStaff(staff.staff_code)}
                                                  className={"text-decoration-underline"}>Delete</span>
                                        </>
                                    }
                                    <span className={"text-decoration-underline"}>Ledger</span>
                                    <span className={"text-decoration-underline"}>Invoice</span>
                                    <span className={"text-decoration-underline"}>Voucher</span>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))}
                    {Array.from({length: Math.max(0, 15 - staffData.length)}).map((_, index) => (
                        <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <Modal centered show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Staff</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Staff Name</FormLabel>
                        <FormControl onChange={handleChange} className={"mb-3"} placeholder={"Staff Name"}
                                     name={"staff_name"}
                                     required></FormControl>
                        <FormLabel>Staff Username</FormLabel>
                        <FormControl onChange={handleChange} className={"mb-3"} placeholder={"Staff Username"}
                                     name={"staff_username"}
                                     required></FormControl>
                        <FormLabel>Staff Password</FormLabel>
                        <FormControl onChange={handleChange} className={"mb-3"} placeholder={"Staff Password"}
                                     name={"staff_password"}></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} variant="primary" onClick={handleModalClose}>
                            Add Staff
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal centered show={editModalShow} onHide={handleEditModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Staff</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleEditFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Staff Name</FormLabel>
                        <FormControl onChange={handleEditChange} className={"mb-3"} value={editForm.staff_name}
                                     placeholder={"Staff Name"}
                                     name={"staff_name"}
                                     required></FormControl>
                        <FormLabel>Staff Username</FormLabel>
                        <FormControl onChange={handleEditChange} className={"mb-3"} value={editForm.staff_username}
                                     placeholder={"Staff Username"} name={"staff_username"}
                                     required></FormControl>
                        <FormLabel>Staff Password</FormLabel>
                        <FormControl onChange={handleEditChange} className={"mb-3"}
                                     placeholder={"Staff Password (Optional)"} name={"staff_password"}
                        ></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} onClick={handleEditModalClose}>
                            Update Staff
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default StaffPage;