import AlertMessage from "../../components/alertMessage";
import {useEffect, useState} from "react";
import {Button, FormControl, FormLabel, Modal} from "react-bootstrap";

const RevenueAccountPage = () => {

    const [modalShow, setModalShow] = useState(false);
    const [accountData, setAccountData] = useState([]);
    const [error, setError] = useState({
        "message": "",
        "type": ""
    });
    const [form, setForm] = useState({
        "account_name": "",
    });
    const [editModalShow, setEditModalShow] = useState(false);
    const [editForm, setEditForm] = useState({
        "account_code": "",
        "account_name": ""
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("http://localhost:5000/api/revenue/");
                const data = await response.json();
                setAccountData(data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    const handleModalShow = () => {
        setModalShow(true);
    }
    const handleModalClose = () => {
        setModalShow(false);
    }

    const handleEditModalShow = (account_code) => {
        const account = accountData.find((account) => account.account_code === account_code);
        console.log(account);
        setEditForm(account);

        setEditModalShow(true);
    }
    const handleEditModalClose = () => {
        setEditModalShow(false);
    }

    const handleEditChange = (e) => {
        setEditForm({
            ...editForm,
            [e.target.name]: e.target.value
        })
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        fetch("http://localhost:5000/api/revenue/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(form),
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Adding Account!",
                        "type": "danger"
                    })
                } else if (res.status === 403) {
                    setModalShow(false);
                    setError({
                        "message": "Account Already Exists!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setModalShow(false);
                    setForm({
                        "account_name": "",
                    })
                    res.json().then((data) => {
                        setAccountData([
                            ...accountData,
                            {
                                "account_code": data.account.account_code,
                                "account_name": data.account.account_name,
                                "created_at": data.account.created_at,
                            }
                        ])
                    })

                    setError({
                        "message": "Account Added Successfully!",
                        "type": "success"
                    })
                }

            })
    }

    const handleEditFormSubmit = (e) => {
        e.preventDefault();
        fetch("http://localhost:5000/api/revenue/edit", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(editForm),
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    "message": "Error Editing Account!",
                    "type": "danger"
                })
            } else if (res.status === 403) {
                setEditModalShow(false);
                setError({
                    "message": "Account Already Exists!",
                    "type": "danger"
                })
            } else if (res.status === 200) {
                setEditModalShow(false);
                res.json().then((data) => {
                    setAccountData(accountData.map((account) => account.account_code === data.account.account_code ? data.account : account))
                })
                setError({
                    "message": "Account Edited Successfully!",
                    "type": "success"
                })
            }
        })
    }

    const handleDelete = (account_code) => {
        fetch(`http://localhost:5000/api/revenue/delete/${account_code}`, {
            method: "GET",
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Deleting Account!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setAccountData(accountData.filter((account) => account.account_code !== account_code));
                    setError({
                        "message": "Account Deleted Successfully!",
                        "type": "success"
                    })
                }
            })
    }

    return (
        <>
            <div className={"d-flex justify-content-between mb-2"}>
                <h3>Revenue Account</h3>
                <button onClick={handleModalShow} className={"btn btn-primary"}>Add New Account</button>
            </div>
            {
                error.message && <AlertMessage message={error.message} type={error.type}/>
            }
            <div className={"my-3"}>
                <table>
                    <thead>
                    <tr>
                        <th className={"fit"}>CODE</th>
                        <th>ACCOUNT TITLE</th>
                        <th className={"equal"}>OPENING</th>
                        <th className={"fit"}>FC</th>
                        <th className={"equal"}>DEBIT</th>
                        <th className={"equal"}>CREDIT</th>
                        <th className={"equal"}>BALANCE</th>
                        <th className={"fit"}>V/T</th>
                        <th className={"fit"}>ACTION</th>
                    </tr>
                    </thead>
                    <tbody>
                    {accountData.map((acc) => (
                        <tr key={acc.account_code}>
                            <td>{acc.account_code}</td>
                            <td>
                                {acc.account_name}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <div className={"d-flex gap-2"}>
                                    <span onClick={() => handleEditModalShow(acc.account_code)}
                                              className={"text-decoration-underline"}>Edit</span>
                                    <span onClick={() => handleDelete(acc.account_code)}
                                          className={"text-decoration-underline"}>Delete</span>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <Modal show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Account</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Account Name</FormLabel>
                        <FormControl onChange={handleChange} placeholder={"Account Name"} name={"account_name"}
                                     required></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} variant="primary" onClick={handleModalClose}>
                            Add Account
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={editModalShow} onHide={handleEditModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Account</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleEditFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Account Name</FormLabel>
                        <FormControl onChange={handleEditChange} value={editForm.account_name}
                                     placeholder={"Account Name"} name={"account_name"}
                                     required></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} variant="success" onClick={handleEditModalClose}>
                            Update Account
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default RevenueAccountPage;