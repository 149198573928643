import AlertMessage from "../../components/alertMessage";
import {Button, FormControl, FormLabel, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import config from "../../global";

const VendorPage = () => {

    const [vendorData, setVendorData] = useState([]);
    const [error, setError] = useState({
        "message": "",
        "type": ""
    });
    const [modalShow, setModalShow] = useState(false);

    const [form, setForm] = useState({
        "vendor_name": ""
    });

    const [editModalShow, setEditModalShow] = useState(false);

    const [editForm, setEditForm] = useState({
        "vendor_code": "",
        "vendor_name": ""
    })

    const is_admin = localStorage.getItem("role") === "admin";

    // ADD VENDOR MODAL
    const handleModalShow = () => {
        setModalShow(true);
    }
    const handleModalClose = () => {
        setModalShow(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL+"/vendor/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                    mode: "cors",
                });
                const data = await response.json();
                setVendorData(data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        fetch(config.API_URL+"/vendor/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            },
            body: JSON.stringify(form),
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Adding Vendor!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setModalShow(false);
                    setForm({
                        "vendor_name": ""
                    })
                    res.json().then((data) => {
                        setVendorData([
                            ...vendorData,
                            {
                                "vendor_code": data.vendor.vendor_code,
                                "vendor_name": data.vendor.vendor_name,
                                "created_at": data.vendor.created_at
                            }
                        ])
                    })

                    setError({
                        "message": "Vendor Added Successfully!",
                        "type": "success"
                    })
                }

            })
    }

    const deleteVendor = (vendor_code) => {
        fetch(config.API_URL+`/vendor/delete/${vendor_code}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            }
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Deleting Vendor!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setVendorData(vendorData.filter((vendor) => vendor.vendor_code !== vendor_code));
                    setError({
                        "message": "Vendor Deleted Successfully!",
                        "type": "danger"
                    })
                }
            })
    }

    const handleEditModalShow = (vendor_code) => {
        const vendor = vendorData.find((vendor) => vendor.vendor_code === vendor_code);
        setEditForm(vendor);

        setEditModalShow(true);
    }

    const handleEditModalClose = () => {
        setEditModalShow(false);
    }

    const handleEditChange = (e) => {
        setEditForm({
            ...editForm,
            [e.target.name]: e.target.value
        })
    }

    const handleEditFormSubmit = (e) => {
        e.preventDefault();
        fetch(config.API_URL+"/vendor/edit", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            },
            body: JSON.stringify(editForm),
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    "message": "Error Editing Vendor!",
                    "type": "danger"
                })
            } else if (res.status === 200) {
                setEditModalShow(false);
                res.json().then((data) => {
                    setVendorData(vendorData.map((vendor) => vendor.vendor_code === data.vendor.vendor_code ? data.vendor : vendor))
                })
                setError({
                    "message": "Vendor Edited Successfully!",
                    "type": "warning"
                })
            }
        })
    }

    return (
        <>
            <div className={"d-flex justify-content-between mb-2"}>
                <h3 className={"fw-bold"}>VENDOR</h3>
                {
                    is_admin && <Button onClick={handleModalShow} variant={"primary"}>ADD VENDOR</Button>
                }
            </div>
            {
                error.message && <AlertMessage message={error.message} type={error.type}/>
            }
            <div className={"my-3"}>
                <table>
                    <thead>
                    <tr>
                        <th className={"fit"}>CODE</th>
                        <th>VENDOR NAME</th>
                        <th className={"equal"}>OPENING</th>
                        <th className={"equal"}>DEBIT</th>
                        <th className={"equal"}>CREDIT</th>
                        <th className={"equal"}>BALANCE</th>
                        <th className={"fit"}>ACTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    {vendorData.map((vendor, index) => (
                        <tr key={index}>
                            <td>{vendor.vendor_code}</td>
                            <td>{vendor.vendor_name}</td>
                            <td></td>
                            <td>{vendor.total_debit}</td>
                            <td>{vendor.total_credit}</td>
                            <td>{vendor.balance}</td>
                            <td>
                                <div className={"d-flex gap-2"}>
                                    {
                                        is_admin && <>
                                            <span onClick={() => handleEditModalShow(vendor.vendor_code)}
                                                  className={"text-decoration-underline"}>Edit</span>
                                            <span onClick={() => deleteVendor(vendor.vendor_code)}
                                                  className={"text-decoration-underline"}>Delete</span>
                                        </>
                                    }
                                    <Link to={"/ledgers/vendor/" + vendor.vendor_code}
                                          className={"text-decoration-none"}>Legders</Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                    {Array.from({length: Math.max(0, 15 - vendorData.length)}).map((_, index) => (
                        <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={"fs-5 fw-bold d-flex align-items-center gap-3"}>
                <span>Total Accounts: </span>
                <span className={"bg-white px-3 py-2 border-color-primary"}>{vendorData.length}</span>
            </div>

            <Modal show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Vendor</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Vendor Name</FormLabel>
                        <FormControl onChange={handleChange} placeholder={"Vendor Name"} name={"vendor_name"}
                                     required></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} variant="primary" onClick={handleModalClose}>
                            Add Vendor
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal show={editModalShow} onHide={handleEditModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Vendor</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleEditFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Edit Vendor Name</FormLabel>
                        <FormControl onChange={handleEditChange} value={editForm.vendor_name}
                                     placeholder={"Vendor Name"} name={"vendor_name"} required></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} variant="success" onClick={handleEditModalClose}>
                            Update Vendor
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default VendorPage;