import {Button, Col, FormControl, FormGroup, FormLabel, FormSelect, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import config from "../../global";
import AlertMessage from "../../components/alertMessage";

const InvoicePage = () => {

    useEffect(() => {
        document.title = "Create New Invoice";
    }, [])

    const [clientData, setClientData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [staffData, setStaffData] = useState([]);
    const [revenueAccData, setRevenueAccData] = useState([]);

    const [error, setError] = useState({
        "message": "",
        "type": ""
    });

    const [invoiceForm, setInvoiceForm] = useState({
        "saleReceivableAmount": '',
        "passportNumber": '',
        "account_name": '',
        "totalPayable": '',
        "totalReceivable": '',
        "totalProfit": ''
    });

    const [rows, setRows] = useState([
        {
            currency: 'pkr',
            fcAmount: '',
            conversionRate: '',
            creditAmount: '',
            vendorCode: '',
            vendorName: ''
        }
    ]);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setInvoiceForm((prevForm) => {
            const updatedForm = {
                ...prevForm,
                [name]: value,
            };

            if (name === "saleReceivableAmount") {
                updatedForm.totalReceivable = parseFloat(updatedForm.saleReceivableAmount).toFixed(2);
            }

            return updatedForm;
        });
    };


    // Client Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/client/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization")
                    },
                });
                const data = await response.json();
                setClientData(data);
            } catch (error) {
                setError(
                    {
                        "message": "Error fetching Client data. Please refresh the page and try again.",
                        "type": "danger"
                    }
                )
            }
        };

        fetchData();
    }, []);
    const handleClientCodeChange = (e) => {
        const selectedClientCode = e.target.value;
        const client = clientData.find((c) => String(c.client_code) === String(selectedClientCode));

        setInvoiceForm((prevForm) => ({
            ...prevForm,
            client_code: selectedClientCode,
            client_name: client ? client.client_name : "", // Set client name if found
        }));


    };

    // Staff Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/staff/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization")
                    },
                });
                const data = await response.json();
                setStaffData(data);
            } catch (error) {
                setError(
                    {
                        "message": "Error fetching Staff data. Please refresh the page and try again.",
                        "type": "danger"
                    }
                )
            }
        };

        fetchData();
    }, [])


    // Vendor Data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/vendor/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization")
                    },
                });
                const data = await response.json();
                setVendorData(data);
            } catch (error) {
                setError(
                    {
                        "message": "Error fetching Vendor data. Please refresh the page and try again.",
                        "type": "danger"
                    }
                )
            }
        };

        fetchData();
    }, [])
    const handleVendorCodeChange = (index, e) => {
        const selectedVendorCode = e.target.value;
        const vendor = vendorData.find((v) => String(v.vendor_code) === String(selectedVendorCode));

        setRows((prevRows) =>
            prevRows.map((row, rowIndex) =>
                rowIndex === index
                    ? {...row, vendorCode: selectedVendorCode, vendorName: vendor ? vendor.vendor_name : ""}
                    : row
            )
        );
    };

    // Function to add a new row
    const addRow = () => {
        setRows([
            ...rows,
            {
                currency: 'pkr',
                fcAmount: '',
                conversionRate: '',
                creditAmount: '',
                vendorCode: '',
                vendorName: ''
            }
        ]);
    };

    // Function to remove a row by index
    const removeRow = (index) => {
        setRows(rows.filter((_, rowIndex) => rowIndex !== index));
    };

    // Function to handle changes in each row
    // Update `invoiceForm` whenever `rows` changes to ensure accurate total
    useEffect(() => {
        const totalPayable = rows.reduce((sum, row) => sum + (parseFloat(row.creditAmount) || 0), 0);
        const totalProfit = parseFloat(invoiceForm.totalReceivable) - totalPayable;

        setInvoiceForm((prevForm) => ({
            ...prevForm,
            totalPayable: totalPayable.toFixed(2),
            totalProfit: totalProfit.toFixed(2),
        }));
    }, [rows, invoiceForm.totalReceivable]);

    const handleRowChange = (index, field, value) => {
        setRows((prevRows) => {
            const updatedRows = prevRows.map((row, rowIndex) => {
                if (rowIndex === index) {
                    const updatedRow = {...row, [field]: value};

                    if (field === "fcAmount" || field === "conversionRate") {
                        const fcAmount = parseFloat(updatedRow.fcAmount) || 0;
                        const conversionRate = parseFloat(updatedRow.conversionRate) || 0;
                        updatedRow.creditAmount = fcAmount * conversionRate;
                    }

                    return updatedRow;
                }
                return row;
            });

            return updatedRows;
        });
    };


    // Revenue Accounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/revenue/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization")
                    },
                });
                const data = await response.json();
                setRevenueAccData(data);
            } catch (error) {
                setError(
                    {
                        "message": "Error fetching Revenue Account data. Please refresh the page and try again.",
                        "type": "danger"
                    }
                )
            }
        };

        fetchData();
    }, [])
    const handleAccountCodeChange = (e) => {
        const selectedAccountCode = e.target.value;
        const account = revenueAccData.find((r) => String(r.account_code) === String(selectedAccountCode));

        setInvoiceForm((prevForm) => ({
            ...prevForm,
            account_code: selectedAccountCode,
            account_name: account ? account.account_name : "",
        }));
    }


    // FORM SUBMISSION
    const handleFormSubmit = (e) => {
        e.preventDefault();

        const params = {
            ...invoiceForm,
            payableBreakup: rows
        }

        try {
            fetch(config.API_URL + "/invoice/create", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem("authorization")
                },
                body: JSON.stringify(params),
            }).then(response => {
                if (response.status === 200) {
                    setError(
                        {
                            "message": "Invoice Created Successfully",
                            "type": "success"
                        }
                    )
                    // clear state
                    setInvoiceForm({
                        saleReceivableAmount: '',
                        passportNumber: '',
                        account_name: '',
                        totalPayable: '',
                        totalReceivable: '',
                        totalProfit: '',
                        client_code: '',
                        description: '',
                        staffCode: '',
                        account_code: ''
                    });
                    setRows([
                        {
                            currency: 'pkr',
                            fcAmount: '',
                            conversionRate: '',
                            creditAmount: '',
                            vendorCode: '',
                            vendorName: ''
                        }
                    ]);
                } else {
                    setError(
                        {
                            "message": "Error Creating Invoice. Please refresh the page and try again.",
                            "type": "danger"
                        }
                    )
                }
            })

        } catch (e) {
            setError(
                {
                    "message": "Error Creating Invoice. Please refresh the page and try again.",
                    "type": "danger"
                }
            )
        }
    }

    return (
        <>
            <form onSubmit={handleFormSubmit}>
                {
                    error.message && <AlertMessage message={error.message} type={error.type}/>
                }
                <div className={"d-flex justify-content-between align-items-center"}>
                    <h4 className={"fw-bold"}>INVOICE</h4>
                    <div>
                        <span className={"fw-bold"}>INVOICE DATE: </span>
                        <FormControl disabled type={"date"} value={new Date().toISOString().split("T")[0]}/>
                    </div>
                </div>
                <Row className={"justify-content-between my-3"}>
                    <Col sm={2}>
                        <FormGroup>
                            <FormLabel className={"fw-bold"}>CODE</FormLabel>
                            <FormSelect placeholder={"Client Code"} onChange={handleClientCodeChange}
                                        required={true}>
                                <option>--Choose Code--</option>
                                {clientData.map((client) => (
                                    <option key={client.client_code} value={client.client_code}>
                                        {client.client_code}
                                    </option>
                                ))}
                            </FormSelect>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <FormLabel className={"fw-bold"}>CLIENT NAME</FormLabel>
                            <FormControl value={invoiceForm.client_name || ""} placeholder={"Client Name"}
                                         disabled={true} required={true}/>
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <FormLabel className={"fw-bold"}>PASSPORT NUMBER</FormLabel>
                            <FormControl value={invoiceForm.passportNumber} name={"passportNumber"}
                                         onChange={handleChange} placeholder={"Passport Number"} required={true}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={"my-3"}>
                    <Col>
                        <FormGroup>
                            <FormLabel className={"fw-bold"}>DESCRIPTION</FormLabel>
                            <FormControl value={invoiceForm.description} name={"description"}
                                         onChange={handleChange} as={"textarea"}
                                         placeholder={"Description"} required={true}></FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={"my-3"}>
                    <Col sm={4}>
                        <FormGroup>
                            <FormLabel className={"fw-bold"}>RECEIVABLE AMOUNT</FormLabel>
                            <FormControl name={"saleReceivableAmount"} value={invoiceForm.saleReceivableAmount}
                                         onChange={handleChange} type={"number"}
                                         placeholder={"Receivable Amount"} required={true}/>
                        </FormGroup>
                    </Col>
                    <Col sm={4} className={"d-flex align-items-end"}>
                        <h4 className={"m-0 text-center flex-grow-1 fw-bold"}>PAYABLE BREAKUP</h4>
                    </Col>
                    <Col sm={4}>
                        <FormGroup>
                            <FormLabel className={"fw-bold"}>C/O</FormLabel>
                            <FormSelect name={"staffCode"} onChange={handleChange} required={true}>
                                <option>--Choose Staff--</option>
                                {staffData.map((staff) => (
                                    <option key={staff.staff_code} value={staff.staff_code}>
                                        {staff.staff_code} - {staff.staff_name}
                                    </option>
                                ))}
                            </FormSelect>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    rows.map((row, index) => (
                        <Row key={index} className={"my-2"}>
                            <Col sm={3} className={"d-flex align-items-end gap-1"}>
                                <FormGroup>
                                    <FormLabel className={"fw-bold"}>FC</FormLabel>
                                    <FormSelect
                                        required={true}
                                        value={row.currency}
                                        onChange={(e) => handleRowChange(index, 'currency', e.target.value)}
                                    >
                                        <option value={"pkr"}>PKR</option>
                                        <option value={"sar"}>SAR</option>
                                        <option value={"usd"}>USD</option>
                                        <option value={"aed"}>AED</option>
                                        <option value={"euro"}>EURO</option>
                                    </FormSelect>
                                </FormGroup>
                                <FormControl
                                    type={"number"}
                                    placeholder={"FC Amount"}
                                    value={row.fcAmount}
                                    required={true}
                                    onChange={(e) => handleRowChange(index, 'fcAmount', e.target.value)}
                                />
                            </Col>
                            <Col sm={4} className={"d-flex align-items-end gap-1"}>
                                <FormGroup>
                                    <FormLabel className={"fw-bold"}>EXR</FormLabel>
                                    <FormControl
                                        type={"number"}
                                        placeholder={"Conversion Rate"}
                                        value={row.conversionRate}
                                        required={true}
                                        onChange={(e) => handleRowChange(index, 'conversionRate', e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel className={"fw-bold"}>AMOUNT</FormLabel>
                                    <FormControl
                                        type={"number"}
                                        placeholder={"Credit Amount"}
                                        value={row.creditAmount}
                                        required={true}
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={4} className={"d-flex align-items-end gap-1"}>
                                <FormGroup>
                                    <FormLabel className={"fw-bold"}>CODE</FormLabel>
                                    <FormSelect
                                        required={true}
                                        value={row.vendorCode}
                                        onChange={(e) => handleVendorCodeChange(index, e)}
                                    >
                                        <option>--Choose Vendor--</option>
                                        {vendorData.map((vendor) => (
                                            <option key={vendor.vendor_code} value={vendor.vendor_code}>
                                                {vendor.vendor_code}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel className={"fw-bold"}>NAME</FormLabel>
                                    <FormControl placeholder={"Vendor Title"} value={row.vendorName} disabled
                                                 required={true}/>
                                </FormGroup>
                            </Col>
                            <Col className={"d-flex align-items-end"}>
                                {rows.length > 1 && (
                                    <Button variant={"danger"} className={"w-100"} onClick={() => removeRow(index)}>
                                        X
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    ))
                }
                <Button variant={"primary"} className={"my-3"} onClick={addRow}>Add Row</Button>
                <div className={"my-4"}>
                    <h4 className={"fw-bold"}>INCOME ACCOUNT</h4>
                    <div className={"row g-2"}>
                        <FormGroup className={"col-6"}>
                            <FormLabel>Income Account Code</FormLabel>
                            <FormSelect onChange={handleAccountCodeChange} name={"account_code"} required={true}>
                                <option>--Choose--</option>
                                {revenueAccData.map((account) => (
                                    <option key={account.account_code} value={account.account_code}>
                                        {account.account_code}
                                    </option>
                                ))}
                            </FormSelect>
                        </FormGroup>
                        <FormGroup className={"col-6"}>
                            <FormLabel>Income Account Title</FormLabel>
                            <FormControl required={true} value={invoiceForm.account_name}
                                         placeholder={"Income Account Title"}
                                         disabled={true}/>
                        </FormGroup>
                    </div>
                </div>
                <div className={"my-4"}>
                    <h4 className={"fw-bold"}>TOTALS</h4>
                    <div className={"row g-2"}>
                        <FormGroup className={"col-6 col-md-4"}>
                            <FormLabel>Total Payable</FormLabel>
                            <FormControl required={true} value={invoiceForm.totalPayable} disabled={true}
                                         placeholder={"Total Payable"}/>
                        </FormGroup>
                        <FormGroup className={"col-6 col-md-4"}>
                            <FormLabel>Total Receivable</FormLabel>
                            <FormControl required={true} value={invoiceForm.totalReceivable} disabled={true}
                                         placeholder={"Total Receivable"}/>
                        </FormGroup>
                        <FormGroup className={"col-12 col-md-4"}>
                            <FormLabel>Total Profit</FormLabel>
                            <FormControl value={invoiceForm.totalProfit} required={true} disabled={true}
                                         placeholder={"Total Profit"}/>
                        </FormGroup>
                    </div>
                </div>
                <div className={"d-flex justify-content-end"}>
                    <Button type={"submit"} variant={"primary"}>Create Invoice</Button>
                </div>
            </form>
        </>
    )
}

export default InvoicePage;