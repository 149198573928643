import {useEffect, useState} from "react";
import {Button, Col, FormControl, FormLabel, FormSelect, Row} from "react-bootstrap";
import config from "../../global";
import AlertMessage from "../../components/alertMessage";

const CashReceivePage = () => {
    const [cashReceiveDetail, setCashReceiveDetail] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [formData, setFormData] = useState({
        client_code: "",
        description: "",
        amount: "",
        cheque_no: "",
    });
    const [error, setError] = useState({
        message: "",
        type: ""
    });
    const [totalAmount, setTotalAmount] = useState(0);

    const is_admin = localStorage.getItem("role") === "admin";

    // Update Total Amount whenever cashReceiveDetail changes
    useEffect(() => {
        // Calculate total amount
        const total = cashReceiveDetail.reduce((acc, item) => acc + parseFloat(item.amount), 0);
        setTotalAmount(total);
    }, [cashReceiveDetail]);

    // Fetch client data
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/client/", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                });
                const data = await response.json();
                setClientData(data);
            } catch (error) {
                setError(
                    {
                        message: "Failed to get all clients. Please refresh the page and try again.",
                        type: "danger"
                    }
                )
            }
        };

        fetchData();
    }, []);

    // Fetch all cash receives
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/cash/all/cash-receive", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                });
                const data = await response.json();
                setCashReceiveDetail(data);
            } catch (error) {
                setError(
                    {
                        message: "Failed to get all cash receives. Please refresh the page and try again.",
                        type: "danger"
                    }
                )
            }
        };

        fetchData();
    }, [])
    // Handle form input change
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSendData = (e) => {

        e.preventDefault()

        fetch(config.API_URL + "/cash/add/cash-receive", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization"),
            },
            body: JSON.stringify(formData)
        }).then(res => {
            if (res.status === 500) {
                setError(
                    {
                        message: "Error Adding Cash Payment!",
                        type: "danger"
                    }
                )
            } else {
                setCashReceiveDetail([]);
                setError(
                    {
                        message: "Cash Payment Added Successfully!",
                        type: "success"
                    }
                )
            }
        })
    }

    return (
        <>
            <div className={"mb-3"}>
                {
                    error.message ? <AlertMessage message={error.message} type={error.type}/> : ""
                }
                {
                    is_admin && <>
                        <h4 className={"fw-bold"}>ADD CASH RECEIVE</h4>
                        <form onSubmit={handleSendData}>
                            <Row>
                                <Col md={2}>
                                    <FormLabel>CLIENT CODE</FormLabel>
                                    <FormSelect
                                        name={"client_code"}
                                        value={formData.client_code}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value={""}>Select Client</option>
                                        {clientData.map((client) => (
                                            <option key={client.client_code} value={client.client_code}>
                                                {client.client_code} - {client.client_name}
                                            </option>
                                        ))}
                                    </FormSelect>
                                </Col>
                                <Col>
                                    <FormLabel>DESCRIPTION</FormLabel>
                                    <FormControl
                                        name={"description"}
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        placeholder={"Description"}
                                        required
                                    ></FormControl>
                                </Col>
                                <Col md={2}>
                                    <FormLabel>AMOUNT</FormLabel>
                                    <FormControl
                                        name={"amount"}
                                        type={"number"}
                                        placeholder={"Amount"}
                                        min={0}
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        required
                                    ></FormControl>
                                </Col>
                                <Col>
                                    <FormLabel>CHEQUE NO</FormLabel>
                                    <FormControl
                                        name={"cheque_no"}
                                        value={formData.cheque_no}
                                        placeholder={"Cheque No"}
                                        onChange={handleInputChange}
                                    ></FormControl>
                                </Col>
                                <Col sm={12} md={1} className={"d-flex align-items-end"}>
                                    <Button className={"w-100 fw-bold"} type={"submit"}>
                                        ADD
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </>
                }
            </div>
            <h4 className={"fw-bold"}>CASH RECEIVE VOUCHER</h4>
            <table>
                <thead>
                <tr>
                    <th>CLIENT CODE</th>
                    <th>DESCRIPTION</th>
                    <th>AMOUNT</th>
                    <th>CHEQUE NO</th>
                    <th>POSTING DATE</th>
                </tr>
                </thead>
                <tbody>
                {cashReceiveDetail.map((cashReceive, index) => (
                    <tr key={index}>
                        <td>{cashReceive.client.code} - {cashReceive.client.name}</td>
                        <td>{cashReceive.description}</td>
                        <td>{cashReceive.amount}</td>
                        <td>{cashReceive.cheque_no}</td>
                        <td>{cashReceive.created_at}</td>
                    </tr>
                ))}
                {Array.from({length: Math.max(0, 10 - cashReceiveDetail.length)}).map((_, index) => (
                    <tr>
                        <td>&nbsp;</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                ))}
                </tbody>
            </table>

            <div className={"d-flex justify-content-end align-items-end gap-3"}>

                <div>
                    <span>TOTAL AMOUNT</span>
                    <div className={"bg-white border-color-primary text-center py-2"}>
                        <h5 className={"m-0"}>{totalAmount}</h5>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CashReceivePage;
