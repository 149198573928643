import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotFoundPage from "../error/404NotFound";
import {Button, Col, Row} from "react-bootstrap";
import config from "../../global";

const VendorLedgerPage = () => {

    const {vendor_code} = useParams()
    const [notFound, setNotFound] = useState(false)
    const [vendorDetails, setVendorDetails] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(config.API_URL + "/vendor/get_full_detail", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": localStorage.getItem("authorization"),
                    },
                    body: JSON.stringify({
                        "vendor_code": vendor_code
                    }),
                });

                if (response.status === 404) {
                    setNotFound(true)
                }

                const data = await response.json();
                setVendorDetails(data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching vendor data:", error);
            }
        };

        fetchData();
    }, [vendor_code])

    if (notFound) {
        return <NotFoundPage/>
    }

    const handlePrint = () => {
        const printableContent = document.getElementById("printable").innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = `
            <h2>${vendorDetails.vendor.name} Ledgers</h2>
            ${printableContent}
        `;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload(); // Reload to restore the original content
    };

    return (
        <>
            {loading ? (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : vendorDetails && (
                <>
                    <div className={"d-flex justify-content-between mb-3"}>
                        <h3 className={"fw-bold"}>{vendorDetails.vendor.name.toUpperCase() } LEDGERS</h3>
                        <Button className={"px-3 fw-bold"} onClick={handlePrint}>PRINT</Button>
                    </div>
                    <div id={"printable"}>
                        <table>
                            <thead>
                            <tr>
                                <th className={"equal"}>DATE</th>
                                <th className={"fit"}>TKT/R#</th>
                                <th className={"equal"}>TYPE</th>
                                <th>DESCRIPTION</th>
                                <th className={"equal"}>OPENING</th>
                                <th className={"equal"}>FC</th>
                                <th className={"equal"}>EX</th>
                                <th className={"equal"}>DEBIT</th>
                                <th className={"equal"}>CREDIT</th>
                                <th className={"equal"}>BALANCE</th>
                                <th className={"fit"}>V/T</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vendorDetails.ledgers.map((ledger) => (
                                <tr key={ledger.receipt_number}>
                                    <td>{ledger.created_at}</td>
                                    <td>{ledger.receipt_number}</td>
                                    <td>{ledger.ledger_type}</td>
                                    <td>{ledger.description}</td>
                                    <td></td>
                                    <td>{ledger.fc_amount}</td>
                                    <td>{ledger.exchange_rate} - {ledger.currency.toUpperCase()}</td>
                                    <td>{ledger.debit || "-"}</td>
                                    <td>{ledger.credit || "-"}</td>
                                    <td>{ledger.balance}</td>
                                    <td>{ledger.type.toUpperCase()}</td>
                                </tr>
                            ))}
                            {
                                Array.from({length: Math.max(0, 15 - vendorDetails.ledgers.length)}).map((_, index) => (
                                    <tr key={index}>
                                        <td>&nbsp;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <Row className={"justify-content-end mt-3"}>
                            <Col sm={12} md={6} className={"d-flex gap-1"}>
                                <div className={"p-3"}>
                                    <h5 className={"fw-bold m-0"}>TOTAL</h5>
                                </div>
                                <div className={"bg-white px-3 border-color-primary"}>
                                    <small className={"color-primary fw-bold"}>CR</small>
                                    <h5 className={"m-0"}>{vendorDetails.total_credit}</h5>
                                </div>
                                <div className={"bg-white px-3 border-color-primary"}>
                                    <small className={"color-primary fw-bold"}>DB</small>
                                    <h5 className={"m-0"}>{vendorDetails.total_debit}</h5>
                                </div>
                                <div className={"bg-white px-3 border-color-primary"}>
                                    <small className={"color-primary fw-bold"}>BL</small>
                                    <h5 className={"m-0"}>{vendorDetails.balance}</h5>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </>
            )
            }
        </>
    );
}

export default VendorLedgerPage;