import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import LoginPage from "./page/login/loginComponent";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import ClientPage from "./page/client/client";
import VendorPage from "./page/vendor/vendor";
import StaffPage from "./page/staff/staff";
import InvoicePage from "./page/invoice/invoice";
import RevenueAccountPage from "./page/accounts/revenueAccounts";
import VendorLedgerPage from "./page/ledgers/vendor";
import NotFoundPage from "./page/error/404NotFound";
import ClientLedgerPage from "./page/ledgers/client";
import ViewInvoicePage from "./page/invoice/view-invoice";
import CashReceivePage from "./page/voucher/cashReceive";
import Header from "./components/header";
import DashboardPage from "./page/dashboard/dashboard";
import CashPaymentPage from "./page/voucher/cashPayment";
import ProtectedRoute from "./components/protectedRoute";

function Layout({ children }) {
    const location = useLocation();

    // Define paths where the header should be hidden
    const hiddenHeaderPaths = ["/", "/login"];
    const shouldShowHeader = !hiddenHeaderPaths.includes(location.pathname);

    return (
        <>
            {shouldShowHeader && <Header />}
            {children}
        </>
    );
}

function App() {

    return (
        <>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<LoginPage/>}/>
                        <Route path="/dashboard" element={<ProtectedRoute><DashboardPage/></ProtectedRoute>}/>
                        <Route path="/client" element={<ProtectedRoute><ClientPage/></ProtectedRoute>}/>

                        <Route path="/vendor" element={<ProtectedRoute><VendorPage/></ProtectedRoute>}/>

                        <Route path={"/ledgers"}>
                            <Route path="vendor/:vendor_code" element={<ProtectedRoute><VendorLedgerPage/></ProtectedRoute>}></Route>
                            <Route path="client/:client_code" element={<ProtectedRoute><ClientLedgerPage/></ProtectedRoute>}></Route>
                        </Route>

                        <Route path={"/invoice"} element={<ProtectedRoute><InvoicePage/></ProtectedRoute>}></Route>

                        <Route path={"/invoice"}>
                            <Route path={"client/:client_code"} element={<ProtectedRoute><ViewInvoicePage/></ProtectedRoute>}></Route>
                        </Route>

                        <Route path={"/voucher"}>
                            <Route path={"cash-receive"} element={<ProtectedRoute><CashReceivePage/></ProtectedRoute>}></Route>
                            <Route path={"cash-pay"} element={<ProtectedRoute><CashPaymentPage/></ProtectedRoute>}></Route>
                        </Route>

                        <Route path="/staff" element={<ProtectedRoute><StaffPage/></ProtectedRoute>}/>
                        <Route path="/account" element={<ProtectedRoute><RevenueAccountPage/></ProtectedRoute>}/>

                        <Route path="*" element={<NotFoundPage/>}></Route>
                    </Routes>
                </Layout>
            </Router>
        </>
    );
}

export default App;
