import {Button, FormControl, FormLabel, Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import AlertMessage from "../../components/alertMessage";
import {Link} from "react-router-dom";
import config from "../../global";

const ClientPage = () => {
    const [clientData, setClientData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [form, setForm] = useState({
        client_name: "",
        total_passenger: 1
    });
    const [editForm, setEditForm] = useState({
        client_code: "",
        client_name: "",
        total_passenger: 1
    })
    const [error, setError] = useState({
        "message": "",
        "type": ""
    });

    const is_admin = localStorage.getItem("role") === "admin";

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.API_URL}/client/`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": localStorage.getItem("authorization")
                        },
                    });
                const data = await response.json();
                setClientData(data);
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    }, []);

    const handleModalShow = () => {
        setModalShow(true);
    }

    const handleEditModalShow = (client_code) => {
        const client = clientData.find((client) => client.client_code === client_code);
        setEditForm(client);

        setEditModalShow(true);
    }
    const handleModalClose = () => {
        setModalShow(false);
    }
    const handleEditModalClose = () => {
        setEditModalShow(false);
    }
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    const handleEditChange = (e) => {
        setEditForm({
            ...editForm,
            [e.target.name]: e.target.value
        })
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();

        fetch(`${config.API_URL}/client/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization")
            },
            body: JSON.stringify(form),
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Adding Client!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setModalShow(false);
                    setForm({
                        "client_name": ""
                    })
                    res.json().then((data) => {
                        setClientData([
                            {
                                "client_code": data.client.client_code,
                                "client_name": data.client.client_name,
                                "total_passenger": data.client.total_passenger,
                                "created_at": data.client.created_at
                            },
                            ...clientData
                        ])
                    })

                    setError({
                        "message": "Client Added Successfully!",
                        "type": "success"
                    })
                }

            })
    }

    const deleteClient = (client_code) => {
        fetch(`${config.API_URL}/client/delete/${client_code}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization")
            }
        })
            .then((res) => {
                if (res.status === 404) {
                    setError({
                        "message": "Error Deleting Client!",
                        "type": "danger"
                    })
                } else if (res.status === 200) {
                    setClientData(clientData.filter((client) => client.client_code !== client_code));
                    setError({
                        "message": "Client Deleted Successfully!",
                        "type": "success"
                    })
                }
            })
    }

    const handleEditFormSubmit = (e) => {
        e.preventDefault();
        fetch(`${config.API_URL}/client/edit`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("authorization")
            },
            body: JSON.stringify(editForm),
        }).then((res) => {
            if (res.status === 404) {
                setError({
                    "message": "Error Editing Client!",
                    "type": "danger"
                })
            } else if (res.status === 200) {
                setEditModalShow(false);
                res.json().then((data) => {
                    setClientData(clientData.map((client) => client.client_code === data.client.client_code ? data.client : client))
                })
                setError({
                    "message": "Client Edited Successfully!",
                    "type": "success"
                })
            }
        })
    }
    return (
        <>

            <div className={"d-flex justify-content-between mb-2"}>
                <h4 className={"fw-bold"}>CLIENTS</h4>
                {
                    is_admin && <Button onClick={handleModalShow} className={"fw-bold"}>ADD NEW CLIENT</Button>
                }
            </div>
            {
                error.message && <AlertMessage message={error.message} type={error.type}/>
            }
            <div className={"my-3"}>
                <table>
                    <thead>
                    <tr>
                        <th className={"fit"}>CODE</th>
                        <th>CLIENT NAME</th>
                        <th>N / PAX</th>
                        <th className={"equal"}>DEBIT</th>
                        <th className={"equal"}>CREDIT</th>
                        <th className={"equal"}>BALANCE</th>
                        <th>C/O</th>
                        <th className={"fit"}>ACTION</th>
                    </tr>
                    </thead>
                    <tbody>
                    {clientData.map((client) => (
                        <tr key={client.client_code}>
                            <td>{client.client_code}</td>
                            <td>{client.client_name}</td>
                            <td>{client.total_passenger}</td>
                            <td>{client.total_debit}</td>
                            <td>{client.total_credit}</td>
                            <td>{client.balance}</td>
                            <td>{client.staff}</td>
                            <td>
                                <div className={"d-flex gap-1"}>
                                    {
                                        is_admin ? <>
                                            <span onClick={() => handleEditModalShow(client.client_code)}
                                                           className={"text-decoration-underline"}>Edit</span>
                                            <span onClick={() => deleteClient(client.client_code)}
                                                  className={"text-decoration-underline"}>Delete</span>
                                        </> : ""
                                    }
                                    <Link to={"/ledgers/client/" + client.client_code}
                                          className={"text-decoration-none"}>Ledger</Link>
                                    <Link to={"/invoice/client/" + client.client_code}
                                          className={"text-decoration-none"}>Invoice</Link>
                                    <span className={"text-decoration-underline"}>Voucher</span>
                                </div>
                            </td>
                        </tr>
                    ))}
                    {Array.from({length: Math.max(0, 15 - clientData.length)}).map((_, index) => (
                        <tr>
                            <td>&nbsp;</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={"fs-5 fw-bold d-flex align-items-center gap-3"}>
                <span>TOTAL ACCOUNTS: </span>
                <span className={"bg-white px-3 py-2 border-color-primary"}>{clientData.length}</span>
            </div>

            <Modal centered show={modalShow} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Client</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Client Name</FormLabel>
                        <FormControl onChange={handleChange} placeholder={"Client Name"} name={"client_name"}
                                     required></FormControl>
                        <FormLabel className={"mt-3"}>Total Passenger</FormLabel>
                        <FormControl onChange={handleChange} type={"number"} min={1} placeholder={"No. of Passengers"}
                                     name={"total_passenger"} required></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} variant="primary" onClick={handleModalClose}>
                            Add Client
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal centered show={editModalShow} onHide={handleEditModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Client</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleEditFormSubmit}>
                    <Modal.Body>
                        <FormLabel>Edit Client Name</FormLabel>
                        <FormControl onChange={handleEditChange} value={editForm.client_name}
                                     placeholder={"Client Name"} name={"client_name"} required></FormControl>
                        <FormLabel className={"mt-3"}>Edit Total Passenger</FormLabel>
                        <FormControl onChange={handleEditChange} value={editForm.total_passenger} type={"number"}
                                     min={1} placeholder={"No. of Passengers"} name={"total_passenger"}
                                     required></FormControl>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={"submit"} onClick={handleEditModalClose}>
                            Update Client
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default ClientPage;