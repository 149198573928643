import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
    const authorization = localStorage.getItem("authorization"); // Check for user_code in localStorage

    if (!authorization) {
        // If not authenticated, redirect to the login page
        console.log("Auth not found!")
        return <Navigate to="/" />;
    }

    // If authenticated, render the children (protected component)
    return children;
}

export default ProtectedRoute;
